<template>
  <PollLikeDislikeEditView disabled />
</template>

<script>
import PollLikeDislikeEditView from '@/views/poll/PollLikeDislikeEditView'

export default {
  name: 'PollLikeDislikeView',
  components: {
    PollLikeDislikeEditView
  }
}
</script>
